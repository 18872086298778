import H2TrapeziumBG from "../TrapeziumBackground/H2TrapeziumBG";
import {IMemberOfComponentProps} from "../../../lib/types/components";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./MemberOfComponentV2.module.css"

const MemberOfComponentV2 = (props: IMemberOfComponentProps) => {

    const {membersData} = props;

    return (
        membersData && Object.keys(membersData).length ?
            <H2TrapeziumBG uniqueContainerId={"memberOf_container"}>
                <div className={multipleClassName("container-fluid", styles.memberContainerV2)}>
                    <div className="container">
                        <div className={styles.headingParent}>
                            <h2 className={styles.heading}>{membersData.headingV2}</h2>
                        </div>
                        <div className={multipleClassName("row", styles.contentContainer)}>
                            <div className={multipleClassName("col-md-6", "col-12", styles.memberOfContainer)}>
                                {/* {membersData.listV2.map((item, index) => {
                                    return ( */}
                                <div className={styles.imageParent} key={"memberOfContainer_"}>
                                    <div className={styles.imageContainer}>
                                        <CustomImageComponent
                                            src={membersData.image || 'https://d3g4wmezrjkwkg.cloudfront.net/website/images/indiaMemberOf.webp'}
                                            alt={"memberof"} layout={"fill"}
                                            objectFit={"contain"}/>
                                    </div>
                                </div>

                                {/* })} */}
                            </div>
                            <div className={multipleClassName("col-md-6", "col-12", styles.featuresContainer)}>
                                <div className={styles.featureItemParent}>
                                    {membersData.features.map((item, index) => {
                                        return (
                                            <div className={styles.featureItemContainer}
                                                 key={"featureItemParent_" + index}>
                                                <div className={styles.itemImageParent}>
                                                    <div className={styles.itemImageContainer}>
                                                        <CustomImageComponent src={item.image.src} alt={item.image.alt}
                                                                              layout={"fill"} objectFit={"contain"}/>
                                                    </div>
                                                </div>
                                                <div className={styles.featureItem} title={item.heading}>
                                                    {item.heading}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </H2TrapeziumBG> : <></>
    )
}

export default MemberOfComponentV2;